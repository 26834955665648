<template>
<div>
    <!-- My Account Basic Style -->
    <div v-if="style == 'basic'">
        <div class="delivery-login-box">
            <div class="delivery-icon">
                <div class="user-box">
                    <Icon name="ri:user-3-line" v-if="!authStore.isLoggedIn"></Icon>  
                    <img :src="authStore.userInfo.user_image ? authStore.userInfo.user_image : '/images/point_logo.png'" class="img-fluid" alt="profile"
                        v-if="authStore.isLoggedIn"  width="50" height="50"/>
                     <!-- <h3 v-else>{{ authStore.user.firstName?.charAt(0)?.toString()?.toUpperCase()}}</h3> -->
                </div>
            </div>
            <div class="delivery-detail">
                <h6 v-if="authStore.isLoggedIn">{{$t("Hi")}}, <b>{{ authStore.userInfo.email  }}</b></h6>
                <h6 v-else>{{$t("Hi Guest")}}</h6>
                <h5>{{$t("My Account")}}</h5>
            </div>
        </div>
        <div class="onhover-div onhover-div-login">
            <ul class="user-box-name" v-show="authStore.isLoggedIn == false">
                <li class="product-box-contain" >
                    <nuxt-link :to="NavigationLinks?.login">{{ $t('Log In') }}</nuxt-link>
                </li>
                <!-- TODO: header register link -->
                <!-- <li class="product-box-contain">
                    <nuxt-link :to="NavigationLinks?.signUp">{{ $t('Register') }}</nuxt-link>
                </li> -->
            </ul>
             <ul class="user-box-name" v-show="authStore.isLoggedIn">
                <li class="product-box-contain">
                    <nuxt-link :to="'/auth/dashboard'">
                        <Icon name="ri:user-3-line" class="me-2"/>{{ $t('My Account') }}
                    </nuxt-link>
                </li>
                <li class="product-box-contain" >
                    <div @click="logout" style="cursor: pointer;">
                        <Icon name="ri:logout-box-r-line" class="me-2"></Icon>{{ $t('Logout') }}
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <!-- My Account Classic Style -->
    <a href="javascript:void(0)" class="user-box" v-if="style == 'classic'">
        <span class="header-icon">
            <Icon name="ri:user-line"></Icon>
        </span>
        <div class="user-name">
            <h6 class="text-content">{{$t("My Account")}}</h6>
            <h4 class="mt-1">{{ authStore?.isLoggedIn ? authStore.user?.email : 'User' }}</h4>
        </div>
    </a>

    <a href="javascript:void(0)" class="header-icon user-icon search-icon" v-if="style == 'standard'">
        <Icon name="ri-user-line"></Icon>
    </a>

    <!-- <WidgetsModalsCommon :showModal="modals.logout" @close="toggleModal({ state: false })">
        <WidgetsModalsConfrmationModal @close="toggleModal({ state: false })" @confirm="authStore.logout()"/>
    </WidgetsModalsCommon> -->
</div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from '~/lib/features/auth/store/auth_store';

const authStore = useAuthStore()

let props = defineProps({
    style: {type:[String,Object],default:'basic'}
})
// useUserStore().setDefaultUser()
let modals = ref({ logout: false })
// let { User, UserInfo } = storeToRefs(useUserStore())

function logout() {
  useNuxtApp().$event('open_confirmation_dialog', {
    title: "Are you Sure",
    content: `Are you sure you want logout of your account: (${authStore.userInfo.email}) `,
    cb: () => {
        authStore.logout()
    }
  })
}

// async function toggleModal({ state = true }) {
//     modals.value.logout = state
// }
 
onMounted(() => {    
// let storedData = localStorage.getItem('usersAccount')
// if(storedData?.isLoggedIn == true){
//     useUserStore().UpdateUserInfo()
//   }
})
</script>